.about-us {
  @include component-template;
  width: 100%;

  .about-us-content {
    width: 868px;
    margin: 0 auto;

    .about-us-text {
      font-size: 1.3rem;
      text-align: left;
      padding: 1rem 1.5rem;
      background-color: $etikett-black-075;
      box-shadow: inset 0px -0.3px 2px 0px rgba(255, 255, 255, 0.1);

      p {
        margin: 1rem 0;

        span {
          color: $etikett-hover;
        }
      }
    }
  }
}
