// Layout
.chat {
  .chat-button {
    margin: 0 0.5rem;
    padding: 0 5px;
    height: 100%;
    white-space: nowrap;
  }

  input,
  select,
  textarea {
    // margin-top: .25rem;
    // background-color: $etikett-black-050;
    background-color: rgba(255, 255, 255, 0.1);
    width: 100%;
    height: 2rem;
    padding-left: 0.5rem;
    border: 0px solid;
    border-bottom: 1px solid rgb(255, 255, 255);
    border-radius: 3px;

    &:focus {
      background-color: $etikett-black;
      outline: none;
      border: 1px solid;
    }
    option {
      color: black;
      font-size: 1rem;
    }
  }
}

.ChatApp {
  width: $chat-width;

  h3 {
    // padding-bottom: .8rem;
    width: 100%;
    color: #fff;
    font-size: 1.3rem;
    color: $etikett-hover;
    letter-spacing: 2px;
    text-decoration-line: underline;
    text-decoration-color: rgba(255, 255, 255, 0.2);
  }
  p {
    font-size: 0.9rem;
  }
}
.outer-container {
  display: flex;
  justify-content: center;
  height: 100%;
}
.inner-container {
  h3 {
    font-size: 1.5rem;
    font-family: $font-light;
  }

  width: 100%;
  display: flex;
  flex-direction: column;
  transition: 0.2s;

  &:hover {
    background-color: $etikett-black-075;
    box-shadow: inset 0.5px 0px 0px 0px rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    transition: 0ms;
  }
}

.chat-app-chat {
  transition: $chat-height-transition;

  .Messages,
  .chat-form {
    animation: display-on 400ms ease forwards;
  }
}

.chat-app-minimize {
  height: 50px;
  transition: $chat-height-transition;
  .Messages,
  .chat-form {
    animation: display-off 400ms ease forwards;
  }
  .Input {
    animation: display-off 100ms ease forwards 300ms;
  }
}
.chat-app-join {
  height: 70px;
}

// Join
.Join {
  display: flex;
  flex-direction: column;
  background-color: $etikett-black;
  // height: 100%;
  .join-form {
    height: 100%;
    min-height: 36px;
    width: 100%;
    input {
      width: 100%;
      padding-left: 5px;
    }
    .button-container {
      margin: 0;
    }
  }
}

// InfoBar
.InfoBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $etikett-black;
  z-index: 2;
  h3 {
    cursor: pointer;
    margin: 0;
  }
  .button-container {
    width: auto;
    margin: 0;
  }
  .info-inner-container img {
    width: 16px;
    margin: 0 0.4rem;
    cursor: pointer;
  }
  .info-inner-container p {
    margin-right: 1rem;
  }
}

// Messages
.Messages {
  padding-left: 1px;
  margin-right: 0.5rem;
  display: flex;
  flex-direction: column-reverse;
  transition: 0.25s;
}

// Needed to display messages upside-down
.Messages div:first-child {
  height: auto;
  scrollbar-base-color: red;
}

// Input
.Input {
  .button-container {
    margin: 0;
  }
}

// Chat Form
.chat-form {
  align-self: flex-end;
  padding-left: 1px;
  button {
    padding: 0 0.5rem;
  }

  #input-message-textarea {
    font-size: 0.9rem;
    padding: 0.3rem;
    overflow-x: hidden;
    resize: none;
    width: 80%;
    height: 32px;
    max-height: 88px;
    align-self: flex-end;
  }
  .chat-send {
    height: 32px;
    width: 20%;
  }
}

// Message
.justify-right {
  text-align: right;
  margin-right: 0.5rem;
  p {
    text-align: left;
  }
}
.justify-left {
  display: flex;
  text-align: left;
}
.message-box {
  display: inline-block;
  max-width: 265px;
  padding: 0.1rem 0.4rem;
  margin-bottom: 0.5rem;
  border-radius: 8px;
}
.bg-white {
  background: $etikett-hover;
  border-radius: 8px 8px 0 8px;
  p {
    color: $etikett-black;
  }
}
.bg-black {
  // background: $etikett-black-025;
  border-radius: 8px 8px 8px 0;
  p {
    color: rgba(255, 255, 255);
  }
}
.message-text {
  overflow-wrap: break-word;
  max-width: 100%;
}
.sent-text {
  padding-top: 0.1rem;
  margin: 0 0.4rem;
  color: $etikett-hover;
  max-width: 100px;
}

// Animation
@keyframes display-off {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    z-index: -1;
  }
}
@keyframes display-on {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/////////////////////
//Styling for header
/////////////////////

.chat {
  background-color: $etikett-black-000;
  position: absolute;
  top: $nav-height;
  right: 0;
  top: 0;
  margin-top: 0.5rem;
  z-index: 3;

  // Chat window height
  // .InfoBar {
  //   height: 40px
  // }

  .Input {
    height: 36px;
  }

  .Messages {
    height: calc(100% - 36px - 40px);
  }

  .chat-form {
    background-color: $etikett-black-000;
    height: 100%;
    display: flex;
    align-items: center;
  }
}
.chat-homescreen-with-video {
  position: fixed;
  top: 0;
  z-index: 3;
  .chat-app-chat {
    height: calc(100vh - 60px);
  }
}

.chat-routes-with-video {
  .chat-app-chat {
    height: calc(100vh - 8px); // 100vh - navbar height (when it's wrapped)
  }
}
