///////////////////////////////////
//          Blog Page
///////////////////////////////////
.blog-component {
  @include component-template;
  white-space: pre-wrap;

  .blog-content {
    width: 100%;
    max-width: 700px;
    text-align: left;
  }

  .post-blog {
    margin-top: 2rem;
  }

  .blog-list {
    margin: 4rem 0;

    h2 {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    .article-header {
      margin: 2rem 0;
      display: flex;
      justify-content: space-between;
    }
  }
}
