.sun {
  width: 19%;
  height: 19%;
  background: url(../img/imageonline-co-noise.png);
  background-size: 140%;
  background-position: center;
  border-radius: 120px;
  position: absolute;
  align-self: center;
  box-shadow: 0 0 55px rgba(255, 255, 255, 1);
}

.planet {
  width: 1px;
  height: 1px;
  margin: auto;
  background: #fff;
  border-radius: 2.5px;
}

.planet.mars {
  width: 10%;
  height: 10%;
  border-radius: 500px;
  margin-top: -5%;
  background: #d3d3d3;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.planet.earth {
  width: 9%;
  height: 9%;
  border-radius: 500px;
  margin-top: -4.5%;
  background: #d3d3d3;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.planet.venus {
  width: 8%;
  height: 8%;
  border-radius: 500px;
  margin-top: -4%;
  background: #d3d3d3;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.planet.mercury {
  width: 8%;
  height: 8%;
  border-radius: 500px;
  margin-top: -4%;
  background: #d3d3d3;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.orbit {
  width: 100%;
  height: 100%;
  position: absolute;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 5000px;
  align-self: center;
  animation: rotating 200s linear infinite;
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
