@font-face {
  font-family: 'Lausanne-300';
  src: url('../font/Lausanne-300.otf');
}
@font-face {
  font-family: 'Lausanne-500';
  src: url('../font/Lausanne-500.otf');
}
@font-face {
  font-family: 'Lausanne-700';
  src: url('../font/Lausanne-700.otf');
}
