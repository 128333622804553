.host-page {
  @include component-template;

  .host-card {
    width: 100%;
    max-width: 1000px;
    display: flex;

    &:hover {
      box-shadow: 0 14px 28px rgba(139, 139, 139, 0.25),
        0 10px 10px rgba(156, 156, 156, 0.22);
      transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    }

    .host-image-detail {
      height: 100%;
      width: 100%;
      max-width: 500px;
      max-height: 500px;
    }

    .host-description {
      @include flex-column;
      justify-content: space-between;
      text-align: left;
      padding: 1rem;

      .about-host {
        height: 100%;
      }

      h3 {
        margin-bottom: 2rem;
      }

      .social-and-pagelink {
        .social-media-container {
          text-align: right;
          margin-top: 1rem;
        }
        .social-media-container > a {
          padding: 1rem 0.75rem;
          font-size: 1.5rem;
        }

        .host-pagelink {
          display: flex;
          justify-content: flex-end;
        }
      }

      a {
        :hover {
          color: $etikett-hover;
          transition: 0.1s;
        }
      }
    }
  }
}

.host-list-component {
  @include component-template;
  width: 100%;

  .grid-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;

    .hosts-list {
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      width: 100%;
      max-width: 950px;

      .hosts-list-item {
        display: flex;
        justify-content: center;
        margin: 0.5rem;
        width: 100%;
        max-width: 300px;
      }

      .link-component {
        text-decoration: none;
        margin: 0;

        h3 {
          max-width: 100%;
        }

        .host-image-borders {
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.486);
          background-color: $etikett-black;
          height: 100%;
          width: 100%;

          .host-images {
            position: relative;
            opacity: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            transition: 0.3s ease-in;

            &:hover {
              opacity: 1;
            }
          }
        }
      }

      .host-name {
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: 100%;
        text-transform: lowercase;
        opacity: 1;
        z-index: 1;
      }
    }
  }
}
// @media only screen and (min-width: breakpoints(big-desktop)) {
// }
@media only screen and (max-width: breakpoints(desktop)) {
  .host-page {
    .host-card {
      h3 {
        margin-top: 0;
      }
      .host-image-detail {
        max-width: 450px;
        max-height: 450px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints(tablet)) {
  .host-page {
    .host-card {
      display: flex;
      flex-direction: column;

      h3 {
        margin-top: 0;
      }
      .host-image-detail {
        max-width: 500px;
        max-height: 500px;
      }
    }
  }
}
@media only screen and (max-width: breakpoints(phone)) {
}
