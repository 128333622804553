* {
  padding: 0;
  margin: 0;
  line-height: 1.3;
  box-sizing: border-box;
  color: white;
  background-color: $etikett-black-000;
  letter-spacing: 0.5px;
  font-family: $font-light;
  font-weight: lighter;
  overflow-wrap: break-word;



  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  &::selection {
    background-color: #ffffff;
    color: $etikett-black;
  }
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}

h1 {
  text-align: center;
  margin: 1rem 0;
  font-family: $font-normal;
  letter-spacing: 3px;
}

h2 {
  text-align: center;
  margin: 1rem 0;

  font-family: $font-bold;
  letter-spacing: 3px;
}

h3 {
  margin-top: 1.5rem;
  font-family: $font-bold;
  letter-spacing: 3px;
}

///////////////////////////////////
//        Overall Layout
///////////////////////////////////
a {
  margin: 1rem 0;
}

i {
  font-size: 2rem;
}

q {
  max-width: 300px;
  text-align: center;
  margin: 0 auto;
  white-space: pre-wrap;
}

li {
  list-style-type: none;
  margin-bottom: 5px;
}

.page-links {
  display: flex;
  align-items: center;
    justify-content: center;
}

.page-links > * {
  padding: 0 1rem;
}

.link-component {
  // text-decoration: none;
  text-decoration-color: rgba(255, 255, 255, 0.2);
  transition: 0.1s;

  &:hover {
    color: $etikett-hover;
    text-decoration-color: $etikett-hover;
    transition: 0.1s;
  }
}

textarea {
  resize: vertical;
}

.Null {
  height: 200vh;
}

.divider {
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin: 0.5rem 0;
}
///////////////////////////////////
//         Main Page
///////////////////////////////////

.noise {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: -1;
  background-color: $etikett-black;
}

.solar-system {
  position: fixed;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vh;
  height: 100vh;
  margin: 0 auto;
  box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.1);
  border-radius: 45vw;
  display: flex;
  justify-content: center;
  align-self: center;
  opacity: 0.8;
}

.stream-page {
  position: relative;
  display: flex;
  justify-content: center;
  .header-background {
    position: absolute;
    width: 100%;
    height: $header-height-small;
    background-color: $etikett-black;
    box-shadow: 0px 0px 15px 15px $etikett-black;
  }
}

footer {
  padding: 2rem 2rem;
  display: flex;
  align-items: flex-end;
  flex-grow: 1;

  .footer-img {
    z-index: -1;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    background-size: 100%;
    background-image: url('../img/footer-img-1920x600.png');
    background-position: bottom;
    background-repeat: no-repeat;
  }

  .footer__socials {
    z-index: 1;
    display: flex;
    width: 100%;
    margin-bottom: 3rem;
  }
}

.airtime-container {
  left: -1rem;
  top: 1rem;
  //transform: scale(0.75);
  transform-origin: top left;
  position: relative;
  z-index: 100;
}

.social-links {
  text-align: left;
}

.social-links > a {
  padding: 1rem;
  font-size: 2rem;
}

.gradient {
  background-image: linear-gradient($etikett-black-025, $etikett-black-000);
}

// ////skip link aria///
// .skip-link {
//   z-index: 9999;
//   transform: translateY(calc(-100% - 1rem));
//   display: block;
//   position: absolute;
//   left: 1.5rem;
//   top: -1rem;
//   height: 50px;
//   width: 100px;
// }

// .skip-link:focus {
//   transform: translateY(0);
//   transition: all 300ms ease-in-out;
// }

.c-links {
  li {
    margin: 0;
  }
}

///////////////////////////////////
//           Loading
///////////////////////////////////
.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;

  img {
    width: 70%;
    height: 70%;
    animation: spin 3s ease-in-out infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

///////////////////////////////////
//          Fade In
///////////////////////////////////
.App-header,
.schedule-page,
.all-list,
.archive-details-page,
.blog-component,
.host-list-container,
.hosts,
.input-form,
.contact-component,
.staff-only,
.etikett-black,
.about-us,
.imprint .log-in-component,
.my-profile-component {
  animation: fadein ease 700ms;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

//---------------------------------------------//
//            From the base react              //
//---------------------------------------------//
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
///////////////////////
// Responsive Design //
///////////////////////
@media only screen and (max-width: breakpoints(desktop)) {
  .header-background {
    display: none;
  }
}
// @media only screen and (max-width: breakpoints(tablet)) {
// }
// @media only screen and (max-width: breakpoints(phone)) {
// }
