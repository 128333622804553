///////////////////////////////////
//          Error Page
///////////////////////////////////
.error-page {
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
