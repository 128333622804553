.imprint {
  @include component-template;

  .imprint-content {
    width: 868px;

    h2 {
      font-size: 2.3rem;
    }

    h3 {
      margin: 0;
      font-size: 2.1rem;
    }

    h4 {
      font-weight: lighter;
      font-size: 1.5rem;
      letter-spacing: 2px;
      margin: 2.5rem 0 1rem;
    }

    section {
      font-size: 1rem;
      line-height: 1.3;
      text-align: left;
      margin-bottom: 3rem;
      padding: 1rem 1.5rem;
      background-color: $etikett-black-075;
      box-shadow: inset 0px -0.3px 2px 0px rgba(255, 255, 255, 0.1);

      p {
        margin: 1.5rem 0;

        span {
          color: $etikett-hover;
        }
      }

      ul {
        margin-top: 0.5rem;
        li {
          list-style-type: square;
          margin-left: 3rem;
        }
      }
    }
    .address-field {
      h2 {
        font-size: 2rem;
        &:first-of-type {
          margin-top: 1rem;
        }
      }
      li {
        list-style-type: none;
        margin-left: 0;
      }
    }
    .website-builder {
      margin-top: 5rem;

      p {
        margin-top: 0;
      }

      div {
        ul {
          display: flex;
          flex-direction: column;

          li {
            list-style-type: none;
            margin: 0 3rem 0 0;
          }
        }
      }
    }
  }
}
