///////////////////////////////////
//          Main Page Bar
///////////////////////////////////
body {
  &::-webkit-scrollbar {
    width: 16px;
    background-color: $etikett-hover;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient($etikett-black, $etikett-black-075);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.05);
    box-shadow: inset rgba(255, 255, 255, 0.2) 0px 0px 0px 1px;
    border-radius: 5px;

    &:hover {
      border-radius: 5px;
      box-shadow: inset rgba(255, 255, 255, 0.8) 0px 0px 2px 0px;
    }
  }
}
///////////////////////////////////
//          Chat Bar
///////////////////////////////////
.show-scroll *,
#input-message-textarea,
select,
textarea {
  //  background-color: white;
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $etikett-black-000;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $etikett-black-000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $etikett-hover;
    box-shadow: inset $etikett-black-075 0px 0px 2px 1px;
    border-radius: 5px;

    &:hover {
      border-radius: 5px;
      box-shadow: inset $etikett-black 0px 0px 0px 0.5px;
      background-color: $etikett-hover;
    }
  }
}
.hide-scroll * {
  &::-webkit-scrollbar {
    width: 4px;
    background-color: $etikett-black-000;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $etikett-black-000;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $etikett-black-000;
    // box-shadow: inset rgba(255, 255, 255, 0.2) 0px 0px 0px 1px;
    border-radius: 5px;

    &:hover {
      border-radius: 5px;
      background-color: $etikett-hover;
    }
  }
}
