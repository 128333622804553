.archive-details-page {
  @include component-template;

  .archive-details-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    box-shadow: 0 7px 14px rgba(139, 139, 139, 0.25),
      0 10px 10px rgba(156, 156, 156, 0.22);
    transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);

    &:hover {
      box-shadow: 0 14px 28px rgba(219, 219, 219, 0.25),
        0 10px 10px rgba(156, 156, 156, 0.22);
      transition: all 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
      background-color: $etikett-black-075;
    }

    .archive-header {
      display: flex;
      width: 100%;
      img {
        max-width: 180px;
        max-height: 180px;
      }
      .header-p-a {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        h1 {
          margin: 0;
        }
        a {
          font-size: 2rem;
          letter-spacing: 3px;
          margin: 0 1rem;
          width: fit-content;
          justify-self: flex-end;
        }
      }
    }

    .description-container {
      display: flex;
      .meta-data {
        width: 180px;
        padding: 1rem;
        p {
          text-align: left;
        }
      }
      q {
        text-align: left;
        max-width: calc(100% - 180px);
        padding: 1rem;
        margin: 0;
        overflow-wrap: break-word;
      }
    }
  }
}

// @media only screen and (min-width: breakpoints(big-desktop)) {
// }
// @media only screen and (max-width: breakpoints(desktop)) {
// }
// @media only screen and (max-width: breakpoints(tablet)) {
// }
@media only screen and (max-width: breakpoints(phone)) {
  .archive-details-page {
    .archive-details-card {
      .archive-header {
        display: block;
        .header-p-a {
          h1,
          a {
            font-size: 1.5rem;
          }
        }
      }
      .description-container {
        display: block;
        padding: 1rem;
        .meta-data {
          width: 100%;
          padding: 0;
          margin-bottom: 1rem;
          p {
            text-align: center;
          }
        }
      }
    }
  }
}
