@media only screen and (min-width: breakpoints(big-desktop)) {
}

@media only screen and (max-width: breakpoints(desktop)) {
  //clickable logo
  .etikett-black {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: pointer;
    background: url(../img/etikett-black.jpg);
    background-repeat: no-repeat;
    background-size: 140%;
    background-position: center;
    border-radius: 100%;
    width: 19vh;
    height: 19vh;
    // align-self: center;
    box-shadow: 0 0 55px rgba(255, 255, 255, 1);

    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0;
      transition: 0.4s ease-in-out;
    }
  }
}

@media only screen and (max-width: breakpoints(tablet)) {
  .chat {
    display: none;
  }

  .solar-system {
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70vh;
    height: 70vh;
    margin: 0 auto;
    box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.1);
    border-radius: 70vw;
    display: flex;
    justify-content: center;
    align-self: center;
    opacity: 0.8;
  }

  //clickable logo
  .etikett-black {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: pointer;
    background: url(../img/etikett-black.jpg);
    background-repeat: no-repeat;
    background-size: 140%;
    background-position: center;
    border-radius: 100%;
    width: 13.2vh;
    height: 13.2vh;
    // align-self: center;
    box-shadow: 0 0 55px rgba(255, 255, 255, 1);

    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0;
      transition: 0.4s ease-in-out;
    }
  }

  //////////////////////////////////////////////////////////
  // Host Component Tablet
  //////////////////////////////////////////////////////////

  //About Us and Imprint
  .imprint {
    .imprint-content {
      width: 90vw;
    }
  }

  .about-us {
    .about-us-content {
      width: 90vw;
    }
  }
}

@media only screen and (max-width: breakpoints(phone)) {
  .list-header.archive-list-grid.sort-by-box {
    display: none;
  }

  // Solar System
  .solar-system {
    position: fixed;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40vh;
    height: 40vh;
    margin: 0 auto;
    box-shadow: inset 0 0 100px rgba(255, 255, 255, 0.1);
    border-radius: 70vw;
    display: flex;
    justify-content: center;
    align-self: center;
    opacity: 0.8;
  }

  .etikett-black {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    cursor: pointer;
    background: url(../img/etikett-black.jpg);
    background-repeat: no-repeat;
    background-size: 140%;
    background-position: center;
    border-radius: 100%;
    width: 9vh;
    height: 9vh;
    // align-self: center;
    box-shadow: 0 0 55px rgba(255, 255, 255, 1);

    transition: 0.4s ease-in-out;

    &:hover {
      opacity: 0;
      transition: 0.4s ease-in-out;
    }
  }

  ///////////////////////////////////////////////////
  // This one is important, not working in Header.scss yet
  // Main Content
  .small-gap {
    margin-top: $mobile-header-height;
  }
  ////////////////////////////////////////////////////

  // Forms

  // All Users
  .all-list .user-list-grid {
    @include flex-column;

    li {
      margin: 0;
    }
  }

  //menü

  .all-list {
    .list-container {
      width: 100%;
      // margin: 0 auto;
    }

    .hosts-list-grid {
      grid-template-columns: 50% 1fr 1fr;
    }

    li {
      &:nth-child(1) {
        justify-self: start;
      }

      &:nth-child(2) {
        justify-self: center;
      }
    }
  }

  .mobile-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: $etikett-black;
    margin-top: 8px;

    &__btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 35px;
      height: 30px;
      cursor: pointer;
      transition: 0.4s;
    }

    &__icon {
      display: block;
      position: relative;
      background: rgba(255, 255, 255, 1);
      width: 90%;
      height: 4px;
      transition: 0.4s;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        background: rgba(255, 255, 255, 1);
        width: 100%;
        height: 4px;
        transition: 0.4s;
      }

      &::after {
        top: 8px;
      }

      &::before {
        top: -8px;
      }
    }

    &__container {
      position: fixed;
      top: 100px;
      left: 0;
      right: 0;
      height: 0;
      transition: 0.5s;
      transition-delay: 0.2s;
      overflow: hidden;
      backdrop-filter: blur(3px);
      background-color: $etikett-black-075;
      font-size: 1.4rem;
    }

    &__list {
      transition: 0.5s;
      transition-delay: 0.5s;
      list-style: none;
      padding-left: 0;
      display: flex;
      flex-direction: column;
    }

    &__item {
      font-size: 26px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba(66, 65, 65, 0.445);
      text-decoration: none;
    }

    &__link {
      text-decoration: none;
      color: #fff;
    }

    &__checkbox {
      display: none;

      &:checked ~ {
        .mobile-menu {
          &__nav {
            opacity: 1;
            transition-delay: 0s;
          }

          &__container {
            height: 100%;
            transition-delay: 0s;
            z-index: 3;
            font-size: 1.4rem;
          }

          &__btn {
            .mobile-menu__icon {
              background: transparent;

              &::before,
              &::after {
                top: 0;
              }

              &::after {
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
              }

              &::before {
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
              }
            }
          }
        }
      }
    }
  }
}
