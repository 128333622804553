.address-field {
  width: 100%;
  max-width: 700px;
  li,
  h2 {
    text-align: left;
  }
  h2 {
    margin-top: 2rem;
  }
}
