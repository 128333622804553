//Main Styling comes from _InputForms.scss

.edit-host-page {
  @include component-template;

  .input-form {
    .grid-container {
      margin-bottom: 2rem;
    }
  }
}
