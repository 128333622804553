///////////////////////////////////
//          Schedule Page
///////////////////////////////////
.schedule-component {
  @include component-template;
  .schedule-content {
    width: 100%;
    max-width: 1200px;

    // iframe {
    //   width: 100%;
    //   max-width: 800px;
    //   height: 600px;
    // }
    .was-live {
      opacity: 0.2;
    }
    .is-live {
      color: red;
    }

    .check-delete {
      margin-left: 1rem;
    }
    .filter-selector-container {
      justify-content: space-around;
    }
    .edit-grid-container {
      grid-template-columns: 1fr;
      width: 100%;
    }
    .post-archive {
      width: 100%;
    }
    .schedule-head {
      width: 100%;
      margin-bottom: 2rem;
    }

    .monthly-schedule {
      display: flex;
      justify-content: space-evenly;
      flex-flow: wrap;
      width: 100%;

      .weekly-schedule {
        padding: 0 0.5rem;

        .day-dates {
          text-align: left;
          box-shadow: inset 0px -0.5px 0.5px 0px rgba(255, 255, 255, 0.2);

          .day-head {
            display: flex;
            justify-content: space-between;
            margin: 3rem 0 1rem;
            font-size: 1.5rem;
            text-transform: lowercase;
            div:nth-child(1) {
              color: $etikett-hover;
              margin-right: 1rem;
              text-decoration: underline;
              text-decoration-color: rgba(255, 255, 255, 0.2);
            }

            div:nth-child(2) {
              color: $etikett-hover;
              font-weight: bold;
              font-size: 0.8rem;
              letter-spacing: 2px;
              padding-top: 3px;
            }
          }

          .day-details {
            margin: 0.25rem 0;
            max-width: 250px;
            display: flex;
            justify-content: space-between;
            align-items: left;
            // overflow-wrap: break-word;

            li:nth-child(1) {
              margin-right: 0.5rem;
              max-width: calc(100% - 100px);
            }
            li:nth-child(2) {
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
// @media only screen and (min-width: breakpoints(big-desktop)) {
// }
// @media only screen and (max-width: breakpoints(desktop)) {
// }
// @media only screen and (max-width: breakpoints(tablet)) {
// }
@media only screen and (max-width: breakpoints(phone)) {
  .schedule-component {
    @include component-template;
    .schedule-content {
      .monthly-schedule {
        .weekly-schedule {
          width: 100%;
          max-width: 400px;
          padding: 0;
          .day-dates {
            .day-details {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
