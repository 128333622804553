// height
$header-height-small: 60px;
$nav-height: 100px;

$embeded-video-height: calc(100vh - 100px);
$mobile-header-height: 150px;
$full-header-nav-height: 47px; //47px is close to 5vh
$full-header-message-height: 47px;
$full-header-video-height: calc(100vh - 47px - 47px);

//colors
$etikett-black: rgb(1, 5, 8);
$etikett-black-075: rgba(1, 5, 8, 0.75);
$etikett-black-050: rgba(1, 5, 8, 0.5);
$etikett-black-025: rgba(1, 5, 8, 0.25);
$etikett-black-000: rgba(1, 5, 8, 0);
$etikett-hover: rgb(70, 120, 131);
$etikett-hover-050: rgb(70, 120, 131, 0.5);

//font
$font-light: 'Lausanne-300', sans-serif;
$font-normal: 'Lausanne-500', sans-serif;
$font-bold: 'Lausanne-700', sans-serif;

//chat
$chat-width: 340px;
$chat-height-transition: height 400ms ease-in;

// mixins
@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin component-template {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 0.5rem;
  margin-top: $header-height-small;
  background-image: linear-gradient(
    $etikett-black,
    $etikett-black-050,
    $etikett-black-025
  );
  @media only screen and (max-width: breakpoints(phone)) {
    margin-top: 150px;
  }
}

// responsiveness
$media-breakpoints: (
  'big-desktop': 1900px,
  'desktop': 1200px,
  'tablet': 900px,
  'phone': 600px,
);

@function breakpoints($breakpoint) {
  @return map-get($media-breakpoints, $breakpoint);
}
