// Firefox
@-moz-document url-prefix() {
  #input-message-textarea {
    max-height: 70px !important;
  }

  .message-box {
    max-width: 230px;
  }
}
