///////////////////////////////////
//         All User Page
///////////////////////////////////
.all-list {
  @include component-template;

  .list-container {
    width: fit-content;
  }

  .list-header,
  .all-data {
    text-align: left;
    display: grid;
    gap: 1rem;
  }
  .all-data {
    margin: 0.5rem 0;
  }
  .list-header {
    margin-bottom: 3rem;
    text-decoration-line: underline;

    li {
      letter-spacing: 3px;
    }

    .sort {
      cursor: pointer;
      letter-spacing: 3px;
      transition: 0.1s;
      text-decoration-color: rgba(255, 255, 255, 0.2);
      &.active {
        color: $etikett-hover;
      }
      &:hover {
        transition: 0.1s;
        color: $etikett-hover;
        text-decoration-line: underline;
        text-decoration-color: $etikett-hover;
      }
    }
  }

  .user-list-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    .button-container {
      margin: 0;
    }
  }

  .host-list-grid {
    grid-template-columns: 1fr 1fr 1fr;
    .button-container {
      margin: 0;
    }
  }

  .archive-list-grid {
    width: 100%;
    max-width: 1000px;
    margin-left: 20px; //to center because of for users invisible checkbox placeholder
    grid-template-columns: 80px 3fr 2fr 2fr 1fr 20px;
    li {
      width: 100%;
      max-width: 250px;
    }
    column-gap: 20px;
  }
  .post-archive {
    margin-bottom: 2rem;
  }

  .img-container {
    display: flex;
    justify-content: flex-end;

    img {
      width: 50px;
      height: 50px;
    }
  }

  li {
    width: 100%;
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: breakpoints(big-desktop)) {
}
@media only screen and (max-width: breakpoints(desktop)) {
  .list-header,
  .all-data {
    :nth-child(3) {
      display: none;
    }
    &.archive-list-grid {
      grid-template-columns: 80px 1fr 1fr 1fr 20px;
    }
  }
}
@media only screen and (max-width: breakpoints(tablet)) {
  .list-header,
  .all-data {
    :nth-child(4) {
      display: none;
    }

    &.archive-list-grid {
      grid-template-columns: 80px 2fr 1fr 20px;
    }
  }
}
@media only screen and (max-width: breakpoints(phone)) {
  .list-header,
  .all-data {
    :nth-child(5) {
      display: none;
    }

    &.archive-list-grid {
      grid-template-columns: 50px 1fr;
    }
  }
  .archive-add-delete-buttons {
    margin: 0;
    justify-content: space-between;
  }
}
