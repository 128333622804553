///////////////////////////////////
//          Staff Only
///////////////////////////////////
.staff-only {
  @include component-template;

  button {
    width: 100%;
  }
  .button-container {
    height: 3rem;
  }

  div {
    max-width: 300px;
  }
}
