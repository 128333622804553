.edit-info-bar-container {
  @include component-template;
  width: 100%;
  .edit-input-form {
    width: 800px;

    input {
      width: 100%;
      padding: 5px;
      font-size: 1.5rem;

      &[type='submit'] {
        width: fit-content;
        padding: 0 5px;
        font-size: 1rem;
      }
    }
  }
}
//
