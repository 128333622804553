///////////////////////////////////
//          Header
///////////////////////////////////
.App-header {
  background-color: $etikett-black;
  display: flex;
  font-size: 1.5rem;
  position: relative;
  z-index: 3;
  box-shadow: 0px 0px 15px 15px $etikett-black;
  width: 100%;
  max-width: 1920px;
}
.full-header {
  flex-direction: column-reverse;
  height: 100vh;
  nav {
    height: $full-header-nav-height;
  }
  .embeded-video {
    position: relative;
    width: 100%;
    height: $embeded-video-height;
  }
  .controls-container {
    height: $full-header-message-height;
  }
  .catalyst-full-header {
    position: absolute;
    top: 0;
    margin: 0.7rem 0;
    img {
      width: 150px;
    }
  }
  .video-or-logo {
    width: 100%;
  }

  .nav-and-message-bar {
    display: block;
    position: absolute;
    min-height: 87px;
    top: 0;
    left: 150px;
    max-width: calc(100% - 510px);
    nav {
      font-size: 1.5rem;
    }
    .message {
      max-width: 100%;
    }
  }
  .box-shadow-container-left,
  .box-shadow-container-right {
    position: relative;
    left: 150px;
    height: 87px;
    display: flex;
    align-items: flex-end;
    width: 0;
    div {
      height: 35px;
    }
  }
  .box-shadow-container-right {
    position: absolute;
    left: calc(100% - 340px);
    top: 0;
  }
}
.small-header {
  position: fixed;
  top: 0;
  height: $header-height-small;
}

nav {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  height: fit-content;
  a {
    margin: 0.5rem 0;
  }
}
.video-or-logo {
  width: 200px;
  position: relative;
  padding: 0.5rem;
  .logo-and-controls {
    height: 100%;
    position: relative;
    text-align: left;
    a {
      img {
        margin-top: 0.5rem;
        width: 50%;
        position: relative;
      }
    }
  }
}

.box-shadow-container-left,
.box-shadow-container-right {
  position: relative;
  pointer-events: none;
  z-index: 3;
  height: 100%;
  display: flex;
  align-items: flex-end;
  div {
    height: 50px;
  }
}
.box-shadow-container-left {
  div {
    box-shadow: 10px 0 5px 10px$etikett-black;
  }
}
.box-shadow-container-right {
  div {
    box-shadow: -10px 0 5px 10px$etikett-black;
  }
}
.nav-and-message-bar {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  max-width: calc(100% - 540px);
  nav {
    z-index: 4;
    width: 100%;
    font-size: 1.5rem;
    padding: 0 0.5rem;
  }
}

.nav-link {
  padding: 0 0.5rem;
  transition: 0.1s;
  letter-spacing: 2px;
  text-decoration-color: rgba(255, 255, 255, 0.2);
  transition: 0.1s;

  &:hover {
    color: $etikett-hover;
    text-decoration-color: $etikett-hover;
    transition: 0.1s;
  }
}
.active-nav {
  color: $etikett-hover;
  text-decoration-color: white;
}

.nav__socials {
  margin-left: auto;
}

///////////////////////////////////
//          Message
///////////////////////////////////

.message {
  position: absolute;
  font-size: 1.3rem;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: calc(100% - 540px - 16px);
  bottom: 0;
  margin: 0 0.5rem;
  margin-bottom: 0.7rem;

  .moving-text {
    white-space: nowrap;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: ticker;
    animation-duration: 15s;
    display: flex;

    .current-time {
      width: 90px;
    }
    div {
      margin-right: 2rem;
    }

    &:hover {
      animation-play-state: paused;
    }
  }
}

@keyframes ticker {
  0% {
    transform: translate3d(150%, 0, 0);
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

// @media only screen and (min-width: breakpoints(big-desktop)) {
// }
@media only screen and (max-width: breakpoints(desktop)) {
  .box-shadow-container-left,
  .box-shadow-container-right {
    display: none;
  }
}
@media only screen and (max-width: breakpoints(tablet)) {
  .nav-and-message-bar {
    max-width: calc(100% - 200px);
  }
  .message {
    max-width: calc(100% - 200px - 16px);
  }
  .full-header {
    .nav-and-message-bar {
      max-width: calc(100% - 150px);
    }
  }
}
@media only screen and (max-width: breakpoints(phone)) {
  // Main Content
  //////////////////////////////////////////////////////
  ///This can be found in Media.scss at line 360 and is for some reason only working there
  // .small-gap {
  //   margin-top: $mobile-header-height;
  // }
  //////////////////////////////////////////////////////
  .video-or-logo {
    width: 100%;
    .logo-and-controls {
      a {
        display: none;
      }
    }
  }
  .small-header {
    height: $mobile-header-height;
    flex-direction: column-reverse;
    padding-top: 0.5rem;
  }
  .nav-and-message-bar {
    height: fit-content;
    width: 100%;
    max-width: 100%;
    height: 70px;
  }
  .controls-container {
    position: relative;
  }
  .message {
    max-width: 100%;
    position: relative;
    height: 25px;
  }

  .nav-app-header {
    padding: 0 0.5rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    a {
      margin: 0.5rem 0;
    }
    nav {
      width: auto;
    }
    img {
      width: 40px;
    }
  }
  .catalyst-full-header {
    display: none;
  }

  .full-header .nav-and-message-bar {
    left: 0;
    .message {
      position: absolute;
      max-width: 100%;
      top: 50px;
    }
  }
}
